import styles from './NavBar.module.css'
import React from "react";
import {useNavigate} from "react-router-dom";

const NavLinks = (props) => {
    const navigate = useNavigate();

    return (
        <ul className={styles.navbarLine}>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <div onClick={() => navigate('/')} className={styles.logo}>
                    <div className={styles.menu__link}>
                        Home
                    </div>
                    <div>
                        <img className={styles.logoImage} src={'/Images/logo.svg'} alt={'logo'} width={150}
                             height={40}/>
                    </div>
                </div>
            </li>
            <li>
                <ul className={styles.menu__list}>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <a className={styles.menu__link} href={'/#aboutBank'}>
                            About Us
                        </a>
                    </li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <a className={styles.menu__link} href={'/#products'}>
                            Products
                        </a>
                    </li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <a className={styles.menu__link} href={'/#roadMap'}>
                            Roadmap
                        </a>
                    </li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <a className={styles.menu__link} href={'/#contactForm'}>
                            Contact
                        </a>
                    </li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <a href={'/#contactForm'}>
                            <button data-goto="contact_bnt" className={styles.contact_bnt}>Contact Us</button>
                        </a>
                    </li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <a href='https://app.forapay.io/login'>
                            <button data-goto="contact_bnt" className={styles.contact_bnt}>Sign In</button>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    )
        ;
}

export default NavLinks;